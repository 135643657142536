import FormLabel from "@/common/components/form-label/FormLabel";
import FormControl from "@mui/material/FormControl";

import Input from "@/common/components/input";
import debounce from "@/common/functions/debounce";
import { TitleAndSubtitleBox } from "@/pages/settings/items";
import RolesBox from "@/pages/settings/company/roles/RolesBox";
import UserManagement from "@/pages/settings/company/users/UserManagement";
import { joinTeam } from "@/redux/reducers/settings/team";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { Box, Divider, Stack } from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CompanyInformationForm from "./CompanyInformationForm";

type CompanyFormProps = {
  type: "create" | "invite";
};

export const CompanyForm = ({ type }: CompanyFormProps) => {
  const [teamCode, setTeamCode] = useState("");
  const form = useForm();
  const { control, setError, clearErrors } = form;
  const [isValidCode, setIsValidCode] = useState(false);
  const [joinErrorMessage, setJoinErrorMessage] = useState("");
  const dispatch = useAppDispatch();
  const joinError = useAppSelector(({ settings }) => settings.team.joinError);
  const loading = useAppSelector(({ settings }) => settings.team.loading);
  const team = useAppSelector(({ settings }) => settings.team.team);

  const debouncedJoin = useMemo(
    () =>
      debounce((value) => {
        dispatch(joinTeam({ code: value }))
          .unwrap()
          .then(() => {
            setTeamCode(value);
            setIsValidCode(!joinError);
            window.location.reload();
          });
      }, 500),
    [dispatch, joinError],
  );

  useEffect(() => {
    if (joinError) {
      setError("code", { type: "validate", message: "Entered code is not valid" });
      setJoinErrorMessage("Entered code is not valid");
    } else {
      clearErrors("code");
      setJoinErrorMessage("");
    }
  }, [joinError, setError, clearErrors]);

  return (
    <>
      {type === "invite" && (
        <Box
          sx={{
            width: "50%",
          }}
        >
          <Stack direction={{ xs: "column", md: "row" }} spacing={2}>
            <FormControl sx={{ width: "100%" }}>
              <Controller
                name="code"
                control={control}
                render={({ field }) => (
                  <>
                    <FormLabel aria-required label="Enter Invite Code to check your team" />
                    <Input
                      {...field}
                      type="text"
                      placeholder="0 0 0 0 0 0 0 - 0 0 0 0"
                      disabled={isValidCode}
                      error={joinErrorMessage}
                      onChange={(e) => {
                        debouncedJoin(e.target.value);
                      }}
                    />
                  </>
                )}
              />
            </FormControl>
          </Stack>
        </Box>
      )}
      {((teamCode && !loading && !joinError) || type === "create" || team?.name) && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "16px",
            marginTop: "16px",
          }}
        >
          {type === "create" && <Divider />}
          <Box
            sx={{
              width: "50%",
            }}
          >
            <CompanyInformationForm team={team} />
          </Box>
          {team && (
            <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
              <TitleAndSubtitleBox title="Roles" subtitle="Existing roles and description" />
              <RolesBox />
              <TitleAndSubtitleBox title="Users" subtitle="Add or remove users to your team and assign roles" />
              <UserManagement />
            </Box>
          )}
        </Box>
      )}
    </>
  );
};
