import { useRef, useCallback } from "react";
import { Box } from "@mui/material";
import { useWavesurfer } from "@wavesurfer/react";

const AudioPlayer = ({ url }: { url: string }) => {
  const containerRef = useRef(null);

  const { wavesurfer, isPlaying } = useWavesurfer({
    container: containerRef,
    height: 20,
    waveColor: "#9FA9C0",
    progressColor: "#374667",
    url: url,
    normalize: true,
    cursorWidth: 0,
    barGap: 1,
    barWidth: 2,
  });

  const onPlayPause = useCallback(() => {
    wavesurfer && wavesurfer.playPause();
  }, [wavesurfer]);

  return (
    <>
      <Box
        sx={{
          background: "#e8f5e9",
          display: "flex",
          width: "100%",
          alignItems: "center",
          padding: "8px",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
          {isPlaying ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              onClick={onPlayPause}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM7 7.75C7 7.33579 7.33579 7 7.75 7H8.25C8.66421 7 9 7.33579 9 7.75V12.25C9 12.6642 8.66421 13 8.25 13H7.75C7.33579 13 7 12.6642 7 12.25V7.75ZM11 7.75C11 7.33579 11.3358 7 11.75 7H12.25C12.6642 7 13 7.33579 13 7.75V12.25C13 12.6642 12.6642 13 12.25 13H11.75C11.3358 13 11 12.6642 11 12.25V7.75Z"
                fill="#1A2135"
              />
            </svg>
          ) : (
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={onPlayPause}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10ZM8.39061 7.09172C8.63141 6.96025 8.92479 6.97076 9.15557 7.11912L12.6556 9.36912C12.8702 9.50712 13 9.7448 13 10C13 10.2552 12.8702 10.4929 12.6556 10.6309L9.15557 12.8809C8.92479 13.0292 8.63141 13.0398 8.39061 12.9083C8.1498 12.7768 8 12.5244 8 12.25V7.75C8 7.47565 8.1498 7.22318 8.39061 7.09172Z"
                fill="#1A2135"
              />
            </svg>
          )}
        </Box>
        <div style={{ width: "100%" }} ref={containerRef} />
      </Box>
    </>
  );
};

export default AudioPlayer;
