import { RowCallLog } from "@/redux/reducers/ai-voice/listCalls";
import { capitalize } from "@/utils/capitalize";
import { Chip, Typography } from "@mui/material";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import dayjsDuration from "dayjs/plugin/duration";
import { CALL_FLOW_OUTCOME_OPTIONS, CALL_FLOW_TYPE_OPTIONS } from "../constants";
import TableHeader from "@/common/components/table-header";
import TableCell from "@/common/components/table-cell";

dayjs.extend(dayjsDuration);

const columnHelper = createColumnHelper<RowCallLog>();

export const CallLogColumns = [
  columnHelper.accessor("contact", {
    header: () => <TableHeader>Contact Name</TableHeader>,
    cell: (info) => {
      const contact = info.getValue();
      const contactFullName = contact ? `${contact?.firstName} ${contact?.lastName}`.trim() : "Test Call";
      return <TableCell>{contactFullName}</TableCell>;
    },
  }),

  columnHelper.accessor("call_flow", {
    header: () => <TableHeader>Call Flow</TableHeader>,
    cell: (info) => {
      const callFlow = info.getValue();
      if (!callFlow) return;
      return <TableCell>{CALL_FLOW_TYPE_OPTIONS[callFlow].label}</TableCell>;
    },
  }),

  columnHelper.accessor("call_outcome", {
    header: () => <TableHeader>Outcome</TableHeader>,
    cell: (info) => {
      const outcome = info.getValue();
      if (!outcome) {
        return;
      }

      return (
        <Chip
          label={CALL_FLOW_OUTCOME_OPTIONS[outcome].label}
          sx={{
            fontWeight: 500,
            bgcolor: CALL_FLOW_OUTCOME_OPTIONS[outcome].bgcolor,
            color: CALL_FLOW_OUTCOME_OPTIONS[outcome].color || "#000",
          }}
        />
      );
    },
  }),

  columnHelper.accessor("call_direction", {
    header: () => <TableHeader>Direction</TableHeader>,
    cell: (info) => {
      const direction = info.getValue();
      if (!direction) {
        return;
      }
      return (
        <Chip
          label={capitalize(direction)}
          sx={{
            fontWeight: 500,
            bgcolor: direction === "INBOUND" ? "#69C0FF80" : "#609C3180",
            maxWidth: "350px",
          }}
        />
      );
    },
  }),

  columnHelper.accessor("call_ended_time", {
    header: () => <Typography variant="body3">Date</Typography>,
    cell: (info) => {
      const date = info.getValue();
      if (!date) {
        return;
      }
      return <TableCell>{dayjs(date).format("MMM DD, YYYY h:mm A")}</TableCell>;
    },
  }),

  columnHelper.accessor("call_began_time", {
    header: () => <Typography variant="body3">Duration</Typography>,
    cell: (info) => {
      const { call_began_time, call_ended_time } = info.row.original;

      if (!call_began_time || !call_ended_time) {
        return <TableCell>No Duration</TableCell>;
      }

      const durationInMs = call_ended_time - call_began_time;

      const formattedDuration = dayjs.duration(durationInMs).format("m[m] s[s]");
      return <TableCell>{formattedDuration}</TableCell>;
    },
  }),
];
