import React from "react";
import TextField from "@mui/material/TextField";
import { Autocomplete as MuiAutocomplete } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { debounce } from "@mui/material/utils";
import Services from "@/services";
import { useFormContext } from "react-hook-form";
import toast from "@/lib/toast";
import { setCurrentProspect } from "@/redux/reducers/prospects/slices/listProspects";
import { Prospect } from "@/redux/reducers/prospects/slices/types";

interface OptionType {
  label: string;
  value: Prospect;
}

export default function ProspectsAutocomplete({ disabled }: { disabled?: boolean }) {
  const appDispatch = useAppDispatch();
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState<OptionType[]>([]);
  const [loading, setLoading] = React.useState(false);
  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const { setValue } = useFormContext();

  const currentProspect = useAppSelector((state) => state.prospects.listProspects.currentProspect);
  const currentProspectOption = {
    label: `${currentProspect?.firstName} ${currentProspect?.lastName}`,
    value: currentProspect,
  };

  const fetchWithDebounce = React.useMemo(
    () =>
      debounce(async (input: string) => {
        try {
          setLoading(true);
          const response = await Services.Prospects.keywordSearchTeamContacts({
            page: 1,
            pageSize: 10,
            searchTermList: input.split(" "),
          });
          if (!response) return;
          const optionsUpdate = response?.data.results?.map((contact) => ({
            label: `${contact.firstName} ${contact.lastName}`,
            value: {
              ...contact,
              createdAt:
                contact.createdAt?.seconds && contact.createdAt?.nanos
                  ? contact.createdAt.seconds * 1000 + Math.round(contact.createdAt?.nanos / 1e6)
                  : 0,
            },
          }));
          if (optionsUpdate) setOptions(optionsUpdate);
        } catch (error) {
          console.error("Error fetching prospects", error);
          toast.error("Error fetching prospects");
        } finally {
          setLoading(false);
        }
      }, 1000),
    [],
  );

  const handleSelect = (event: any, option: OptionType | null) => {
    if (!option) return;
    appDispatch(setCurrentProspect(option.value));
  };

  React.useEffect(() => {
    let active = true;

    if (currentProspect) {
      return;
    }

    if (!loading) {
      return undefined;
    }

    if (active && prospects && prospects.length > 0) {
      const prospectOptions = prospects.map((prospect) => ({
        label: `${prospect.firstName} ${prospect.lastName}`,
        value: prospect,
      }));
      setOptions(prospectOptions);
    }

    return () => {
      active = false;
    };
  }, [loading, prospects, currentProspect]);

  React.useEffect(() => {
    if (currentProspect) {
      return;
    }
    if (!open) {
      setOptions([]);
    }
  }, [open, currentProspect]);

  React.useEffect(() => {
    if (currentProspect && currentProspect.id) {
      setOptions([{ label: `${currentProspect.firstName} ${currentProspect.lastName}`, value: currentProspect }]);
    }
  }, [currentProspect]);

  return (
    <MuiAutocomplete
      disabled={disabled}
      id="async-prospects-autocomplete"
      open={open}
      defaultValue={currentProspectOption}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      options={options}
      getOptionLabel={(option) => option.label}
      loading={loading}
      onChange={handleSelect}
      onInputChange={(event, newInputValue, reason) => {
        if (event?.type !== "click") {
          setLoading(true);
          fetchWithDebounce(newInputValue);
        } else {
          const selectedProspect = options.find((option) => option.label === newInputValue);
          const prospectId = selectedProspect?.value;
          // TODO no longer needed
          setValue("prospectId", prospectId);
        }
      }}
      sx={{
        "& .MuiInputBase-root": {
          padding: "0px 9px",
        },

        "& .MuiFormLabel-root": {
          fontSize: "14px",
        },
        "& .MuiAutocomplete-inputRoot": {
          height: "44px",
        },

        "& .MuiAutocomplete-listbox": {
          "& .MuiAutocomplete-option": {
            padding: "10px 12px",
            "&[data-focus='true']": {
              backgroundColor: "#E7EBED",
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}
