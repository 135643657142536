import { useAppDispatch, useAppSelector } from "@/redux/store";
import { ActionButtons } from "./ActionButtons";
import { useEffect, useState } from "react";
import { getCallCurrentInbox } from "@/redux/reducers/inbox/slices/getCurrentInbox";
import CallsMessages from "./CallsMessages";
import { Box, Divider } from "@mui/material";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";
import AiAgentList from "./AiAgentList";
import { sendCall } from "@/redux/reducers/ai-voice/send-call";
import { AiAgentCallOutboundRequest } from "@/services/generated";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import { closeViewDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import NoData from "./NoData";

export default function CallsTab() {
  const dispatch = useAppDispatch();

  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const pendingCallCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.pendingCallCurrentInbox);
  const callCurrentInbox = useAppSelector(({ inbox }) => inbox.getInboxData.callCurrentInbox);
  const [showVoiceAgents, setShowVoiceAgents] = useState(false);
  const [selectedAgentId, setSelectedAgentId] = useState<string | null>(null);
  const sendCallPending = useAppSelector(({ aiVoice }) => aiVoice.listCalls.sendCallPending);

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(
        getCallCurrentInbox({
          prospectId: currentProspect?.id,
        }),
      );
    }
  }, [currentProspect, dispatch]);

  const onSubmit = async () => {
    if (!showVoiceAgents) {
      setShowVoiceAgents(true);
      return;
    } else {
      if (!selectedAgentId || !currentProspect?.id) {
        return;
      }

      const data: AiAgentCallOutboundRequest = {
        contact_id: currentProspect?.id,
        ai_agent_id: selectedAgentId,
      };

      dispatch(sendCall(data)).then(() => {
        dispatch(closeViewDrawer());
      });
    }
  };

  if (pendingCallCurrentInbox) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <FullLoadingOverlay loading={sendCallPending} overlay={true} />

      <Box
        className="hide_scroll"
        sx={{
          height: "100%",
        }}
      >
        {callCurrentInbox?.length > 0 ? <CallsMessages /> : <NoData message="No calls made yet" />}
      </Box>

      {showVoiceAgents ? (
        <Box
          className="hide_scroll"
          sx={{
            maxHeight: "600px",
            height: "100%",
          }}
        >
          <AiAgentList setSelectedAgentId={setSelectedAgentId} selectedAgentId={selectedAgentId} />
        </Box>
      ) : null}

      <Box>
        <Divider sx={{ margin: "16px 0px" }} />
        <ActionButtons
          sendText="Make a Call"
          disabled={!showVoiceAgents ? false : !selectedAgentId || sendCallPending}
          showGenerate={false}
          onSubmit={onSubmit}
        />
      </Box>
    </Box>
  );
}
