import * as React from "react";
import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Divider from "@mui/material/Divider";
import ListItemText from "@mui/material/ListItemText";
import NotificationLabel from "@/common/components/notification-label";
import useHeaderMenuItems from "@/common/hooks/useHeaderMenuItems";
import Avatar from "@/common/components/avatar/Avatar";
import { useAppSelector } from "@/redux/store";

export default function DropdownMenu() {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const { headerMenuItems } = useHeaderMenuItems();
  const { user } = useAppSelector(({ settings }) => settings.user);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Box sx={{ display: "flex", alignItems: "center", textAlign: "center", cursor: "pointer" }} onClick={handleClick}>
        <Avatar withLabel={false} label={user?.firstName || ""} src={user?.imgUrl} size="medium" />
      </Box>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {
            minWidth: 300,
            overflow: "visible",
            boxShadow: "0px 6px 15px 0px rgba(55, 70, 103, 0.15)",
            padding: "4px",
            border: "1px solid #E7EBED",
            borderRadius: "8px",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 20,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            ".MuiList-padding": {
              padding: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        {headerMenuItems.map((item, index) => (
          <div key={item.label}>
            <MenuItem
              key={index}
              onClick={item.onClick}
              sx={(theme) => ({
                color: "#1A2135",
                padding: "10px 12px",
                borderRadius: "8px",
                // hover
                "&:hover": {
                  background: "transparent",
                },
              })}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText>
                <NotificationLabel
                  label={item.label}
                  notifications={{
                    count: 2,
                    label: "Messages",
                  }}
                />
              </ListItemText>
            </MenuItem>
            <Divider
              sx={{
                margin: "0 auto !important",
                display: index < headerMenuItems.length - 1 ? "block" : "none",
              }}
            />
          </div>
        ))}
      </Menu>
    </>
  );
}
