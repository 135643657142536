import Box from "@mui/material/Box";
import { useEffect, useMemo, useState } from "react";
import Dropdown from "@/common/components/dropdown/Dropdown";
import Searchbar from "@/common/components/searchbar/Searchbar";
import { KnCompanyImportJobImportJobTypeEnum } from "@/services/generated";
import { DataImportFiltersInput } from "@/pages/data-import";
import { debounce } from "lodash";

export const ImportObjectTypeOptions = [
  { label: "Contact", value: KnCompanyImportJobImportJobTypeEnum.Contact },
  { label: "Company", value: KnCompanyImportJobImportJobTypeEnum.Company },
  { label: "Product", value: KnCompanyImportJobImportJobTypeEnum.Product },
];

export type DataImportFiltersProps = {
  filters: DataImportFiltersInput;
  setFilters: (
    filters: DataImportFiltersInput | ((prevFilters: DataImportFiltersInput) => DataImportFiltersInput),
  ) => void;
};

export default function DataImportFilters({ filters, setFilters }: DataImportFiltersProps) {
  const [search, setSearch] = useState("");

  const debouncedSetSearch = useMemo(
    () =>
      debounce((value: string) => {
        setFilters((prevFilters) => ({
          ...prevFilters,
          search: value,
        }));
      }, 500),
    [setFilters],
  );

  useEffect(() => {
    debouncedSetSearch(search);
  }, [debouncedSetSearch, search]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "16px",
      }}
    >
      <Box sx={{ minWidth: 300 }}>
        <Searchbar placeholder="Search by Name" onChange={(e) => setSearch(e.target.value)} value={search} />
      </Box>
      <Box sx={{ minWidth: 300 }}>
        <Dropdown
          label="Object Type"
          options={ImportObjectTypeOptions}
          placeholder="Object Type"
          value={filters.objectType}
          onChange={(option) => setFilters((prev) => ({ ...prev, objectType: option.value || undefined }))}
        />
      </Box>
    </Box>
  );
}
