import TableActionButton from "./ActionButton";
import StatusChip from "@/common/components/status-chip";
import TaskTableType from "./type";
import { createColumnHelper } from "@tanstack/react-table";
import { TaskRequestStatusEnum } from "@/services/generated";
import { TaskFiltersProps } from "../filters/TaskFilters";
import TableSortBy from "@/common/components/table-sortby/TableSortBy";
import TableHeader from "@/common/components/table-header";
import TableDate from "@/common/components/table-date";
import TableCell from "@/common/components/table-cell";
import createCheckboxCol from "@/common/functions/createCheckboxCol";

const columnHelper = createColumnHelper<any>();

export const TaskColumns = ({ filters, setFilters }: TaskFiltersProps) => {
  const updateSort = () => {
    const newSortDirection =
      filters?.sortDirection === undefined ? "desc" : filters?.sortDirection === "desc" ? "asc" : undefined;

    setFilters((previousFilters) => ({
      ...previousFilters,
      sortBy: "dueDate",
      sortDirection: newSortDirection,
    }));
  };

  return [
    createCheckboxCol(columnHelper),

    columnHelper.accessor("type", {
      header: () => <TableHeader>Type</TableHeader>,
      cell: (info) => {
        const type = info.getValue();

        return (
          <TaskTableType
            action={type}
            prospectName={info.row.original.prospect?.firstName + " " + info.row.original.prospect?.lastName}
          />
        );
      },
    }),

    columnHelper.accessor("assignee.name", {
      header: () => <TableHeader>Assignee</TableHeader>,
      cell: (info) => {
        const assignee = info.getValue() || "Unassigned";
        return <TableCell>{assignee}</TableCell>;
      },
    }),
    columnHelper.accessor("dueDate", {
      header: () => (
        <TableHeader rightIcon={<TableSortBy sortDirection={filters?.sortDirection} onClick={updateSort} />}>
          Due Date
        </TableHeader>
      ),
      cell: (info) => {
        const dueDate = info.getValue();
        return <TableDate date={dueDate} />;
      },
    }),
    columnHelper.accessor("status", {
      header: () => <TableHeader>Status</TableHeader>,
      cell: (info) => {
        const status = info.getValue() || TaskRequestStatusEnum.Due;
        return <StatusChip status={status as TaskRequestStatusEnum} type="task" />;
      },
    }),

    columnHelper.display({
      id: "actions",
      cell: (props) => <TableActionButton row={props.row} />,
    }),
  ];
};
