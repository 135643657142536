import { useUploadPercents } from "@/common/components/drag-and-drop/useUploadPercent";
import Dropdown from "@/common/components/dropdown/Dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input/Input";
import { TextEditor } from "@/common/components/text-editor/TextEditor";
import { getConsentsForCurrentUser } from "@/redux/reducers/consent/slices/getConsentsForCurrentUser";
import { closeSendNewMessageDrawer } from "@/redux/reducers/inbox/slices/getInboxData";
import { getEmailCurrentInbox } from "@/redux/reducers/inbox/slices/getCurrentInbox";
import { sendEmail } from "@/redux/reducers/inbox/slices/sendEmail";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { SmsDTOActionEnum } from "@/services/generated";
import { useAuth0 } from "@auth0/auth0-react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { yupResolver } from "@hookform/resolvers/yup";
import { Divider, FormControl } from "@mui/material";
import { useEffect } from "react";
import { Controller, FormProvider, SubmitHandler, useForm } from "react-hook-form";
import * as Yup from "yup";
import { ActionButtons } from "./ActionButtons";
import EmailMessages from "./EmailMessages";
import { Box } from "@mui/material";

const ProgressBar = ({ percent }: { percent: number }) => {
  return (
    <div
      style={{
        width: "100px",
        height: "8px",
        background: "#FFF",
        borderRadius: "4px",
        position: "relative",
      }}
    >
      <div
        style={{
          width: `${percent}%`,
          height: "100%",
          background: "#374667",
          borderRadius: "4px",
        }}
      ></div>
    </div>
  );
};

const schema = Yup.object({
  from: Yup.string().label("From").required(),
  subject: Yup.string().label("Subject").required(),
  message: Yup.string().label("Message").required(),
  attachments: Yup.array().of(
    Yup.object().shape({
      media: Yup.mixed().required(),
      name: Yup.string().required(),
    }),
  ),
});

export type Form = Yup.InferType<typeof schema>;

export const EmailTab = ({ newMessage = false }: { newMessage?: boolean }) => {
  const { percents, handleDropInterval, addFile, isUploading } = useUploadPercents(); // Inicializa el hook
  const dispatch = useAppDispatch();
  const { user } = useAuth0();
  const currentProspect = useAppSelector(({ prospects }) => prospects.listProspects.currentProspect);
  const prospectName = currentProspect?.firstName + " " + currentProspect?.lastName;
  const consents = useAppSelector((state) => state.consent.consents);

  const methods = useForm<Form>({
    resolver: yupResolver(schema),
    defaultValues: {
      from: "",
      subject: "",
      message: "",
      attachments: [],
    },
  });
  const { control, reset, watch, setValue } = methods;
  const attachments = watch("attachments");

  useEffect(() => {
    if (currentProspect?.id) {
      dispatch(
        getEmailCurrentInbox({
          prospectId: currentProspect?.id,
        }),
      );
    }
  }, [currentProspect?.id, dispatch]);

  useEffect(() => {
    if (consents?.length === 0) dispatch(getConsentsForCurrentUser());
  }, [consents, dispatch]);

  const onSubmit: SubmitHandler<Form> = (data) => {
    // TODO attachments
    dispatch(
      sendEmail({
        team_id: user?.team_id,
        from_user_id: user?.sub,
        from: data?.from,
        prospect_id: currentProspect?.id,
        action: SmsDTOActionEnum.Send,
        subject: data?.subject,
        body: data?.message,
        source: "OUTBOUND",
      }),
    ).then(() => {
      dispatch(getEmailCurrentInbox({ prospectId: currentProspect?.id }));
      dispatch(closeSendNewMessageDrawer());

      reset();
    });
  };

  const bytesToKB = (bytes: number) => {
    return Math.round(bytes / 1024);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      {!newMessage ? <EmailMessages /> : <Box />}

      <FormProvider {...methods}>
        <form>
          <div style={{ display: "flex", flexDirection: "column", margin: "0px 1px", marginTop: "8px" }}>
            <div style={{ border: "1px solid #E9ECEF", borderRadius: "6px" }}>
              <FormControl sx={{ flexGrow: 1, width: "100%", borderRadius: "0px" }}>
                <Controller
                  name="from"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div style={{ zIndex: 2, padding: "8px" }}>
                        <FormLabel label="Sender Email" aria-required infoIcon={true} />
                        <Dropdown
                          {...field}
                          options={consents?.map((consent: any) => ({
                            value: consent?.emailAddress,
                            label: consent?.emailAddress,
                          }))}
                          onChange={(option) => field.onChange(option.value)}
                          value={field.value ? field.value : undefined}
                        />
                      </div>
                    );
                  }}
                />
              </FormControl>
              <FormControl sx={{ flexGrow: 1, width: "100%", borderRadius: "0px" }}>
                <div style={{ position: "relative", display: "flex", gap: "30px", width: "100%" }}>
                  <FormLabel
                    label="To"
                    sx={{
                      position: "absolute",
                      bottom: 4,
                      left: "10px",
                      zIndex: 1,
                    }}
                  />
                  <Input
                    hasRoundedBottomBorders={false}
                    hasRoundedTopBorders={false}
                    leftPadding={20}
                    disabled={true}
                    value={currentProspect ? prospectName : ""}
                    hasSideBorders={false}
                    hasBorder={false}
                  />
                </div>
              </FormControl>
              <FormControl sx={{ flexGrow: 1, width: "100%" }}>
                <Controller
                  name="subject"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <FormLabel
                          label="Subject"
                          sx={{
                            position: "absolute",
                            bottom: 4,
                            left: "10px",
                            zIndex: 1,
                          }}
                        />
                        <Input
                          {...field}
                          hasRoundedBottomBorders={false}
                          hasRoundedTopBorders={false}
                          leftPadding={50}
                          placeholder="Please enter a subject"
                          hasSideBorders={false}
                        />
                      </div>
                    );
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "100%" }}>
                <Controller
                  name="message"
                  control={control}
                  render={({ field }) => {
                    return <TextEditor {...field} handleDropInterval={handleDropInterval} addFile={addFile} />;
                  }}
                />
              </FormControl>
            </div>
            {attachments && attachments?.length ? (
              <div>
                <FormLabel label="Attachments" />
                <div>
                  {attachments?.map((file: any, index: number) => (
                    <div
                      key={index}
                      style={{
                        padding: "16px",
                        background: "#F2F4F8",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ display: "flex", gap: "8px" }}>
                        <span
                          style={{
                            color: percents[index] === 100 ? "#2C72E2" : "#000",
                          }}
                        >
                          {file.name}
                        </span>
                        {percents[index] === 100 ? <span>({bytesToKB(file.media.size)}kb)</span> : null}
                      </div>

                      <div style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                        {isUploading && percents[index] < 100 ? <ProgressBar percent={percents[index]} /> : null}
                        <XMarkIcon
                          style={{ cursor: "pointer", width: "24px", height: "24px" }}
                          onClick={() =>
                            setValue(
                              "attachments",
                              attachments.filter(
                                (attachment: any, attachmentIndex: number) => attachmentIndex !== index,
                              ),
                            )
                          }
                        />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : null}
          </div>
          <Box>
            <Divider sx={{ margin: "16px 0px" }} />
            <ActionButtons sendText="Send Email" onSubmit={onSubmit} />
          </Box>
        </form>
      </FormProvider>
    </Box>
  );
};
