import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import EditProspectDrawer from "@/pages/prospects/edit/EditProspectDrawer";
import ViewProspectDrawer from "./view/ViewProspectDrawer";
import Button from "@/common/components/button/Button";
import { useNavigate } from "react-router";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import {
  getProspects,
  hideSeqEnrollDialog,
  openEditDrawer,
  searchProspects,
  showSeqEnrollDialog,
  triggerSeqEnrollContactIdList,
} from "@/redux/reducers/prospects/slices/listProspects";
import { getCompanies } from "@/redux/reducers/prospects/companies/slices/listCompanies";
import { usePagination } from "@/common/components/pagination/usePagination";
import { ProspectColumns } from "./table/Columns";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { setCurrentProspect, openViewDrawer } from "@/redux/reducers/prospects/slices/listProspects";
import { Prospect } from "@/redux/reducers/prospects/slices/types";
import TsTable from "@/common/components/ts-table";
import { FullLoadingOverlay } from "@/common/components/loading-overlay/FullLoadingOverlay";
import routes from "@/common/constants/routes";
import { getTeamUsers } from "@/redux/reducers/team/slices/listUsers";
import PageHeader from "@/common/components/page-header/PageHeader";
import { IdentificationIcon } from "@heroicons/react/24/outline";
import toast from "@/lib/toast";
import { FormProvider, useForm } from "react-hook-form";
import SeqEnrollDialog from "@/common/components/enroll-in-sequence/SeqEnrollDialog";
import ProspectFilters from "./filters";
import { getConsentFromSources } from "@/common/functions/consent";
import { LoadingSpinner } from "@/common/components/loading-overlay/LoadingSpinner";

export type ProspectsFilterInput = {
  searchString?: string;
};

export default function ProspectsPage() {
  const { t } = useTranslation();

  const prospects = useAppSelector(({ prospects }) => prospects.listProspects.prospects);
  const prospectsTotal = useAppSelector(({ prospects }) => prospects.listProspects.totalCount);
  const prospectsLoading = useAppSelector(({ prospects }) => prospects.listProspects.loading);
  const createProspectIsLoading = useAppSelector(({ prospects }) => prospects.listProspects.createProspectIsLoading);
  const updateProspectIsLoading = useAppSelector(({ prospects }) => prospects.listProspects.updateProspectIsLoading);

  const seqEnrollShowDialog = useAppSelector(({ prospects }) => prospects.listProspects.isShowSeqEnrollDialog);
  const seqEnrollPending = useAppSelector(({ prospects }) => prospects.listProspects.seqEnrollPending);

  const companies = useAppSelector(({ companies }) => companies.listCompanies.companies);
  const users = useAppSelector(({ team }) => team.listUsers.users);

  const [filters, setFilters] = useState<ProspectsFilterInput>({});
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { onPaginationChange, pagination } = usePagination();

  const methods = useForm({});

  useEffect(() => {
    if (filters?.searchString) {
      dispatch(
        searchProspects({
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
          searchTerm: filters.searchString,
        }),
      );
    } else {
      dispatch(
        getProspects({
          ...filters,
          page: pagination.pageIndex,
          pageSize: pagination.pageSize,
        }),
      );
    }
  }, [dispatch, pagination.pageIndex, pagination.pageSize, filters]);

  useEffect(() => {
    if (companies === undefined) {
      dispatch(getCompanies());
    }
  }, [dispatch, companies]);

  useEffect(() => {
    dispatch(getTeamUsers());
  }, [dispatch]);

  const data = useMemo(() => {
    return (
      prospects?.map((prospect) => {
        const owner = users?.find((user) => user.user_id === prospect.ownerUserId);

        return {
          ...prospect,
          consent: getConsentFromSources(prospect.consentSources),
          ownerUserFullName: `${owner?.firstName || ""} ${owner?.lastName || ""}`.trim(),
        };
      }) || []
    );
  }, [users, prospects]);

  const handleNavigate = () => {
    navigate(routes.dataImport.import.path);
  };

  const handleCellClick = (id: any) => {
    const prospect = prospects?.find((prospect: Prospect) => prospect.id === id);
    dispatch(setCurrentProspect(prospect));
    dispatch(openViewDrawer());
  };

  const openMultiSeqEnroll = () => {
    dispatch(showSeqEnrollDialog());
  };

  const handleSeqEnrollClose = () => {
    dispatch(hideSeqEnrollDialog());
  };

  const table = useReactTable<any>({
    data: data || [],
    columns: ProspectColumns,
    getCoreRowModel: getCoreRowModel(),
    pageCount: prospectsTotal,
    manualPagination: true,
    state: {
      pagination: {
        pageSize: pagination.pageSize,
        pageIndex: pagination.pageIndex,
      },
    },
    onPaginationChange,
  });

  const selected = table.getSelectedRowModel();
  const selectedContactIdList = useMemo(() => {
    return selected.rows.map((r) => r.original.id);
  }, [selected.rows]);

  /**
   * Callback for Dialog submit.
   * @param sequenceId
   */
  const handleSeqEnroll = (sequenceId: string) => {
    console.log(selectedContactIdList);
    if (selectedContactIdList.length === 0) {
      return;
    }
    dispatch(
      triggerSeqEnrollContactIdList({
        sequenceTemplateId: sequenceId,
        contacts: selectedContactIdList,
      }),
    ).then(() => {
      dispatch(hideSeqEnrollDialog());
      toast.success("Contact was enrolled in a sequence!");
    });
  };

  return (
    <FormProvider {...methods}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <FullLoadingOverlay
          loading={createProspectIsLoading || updateProspectIsLoading || seqEnrollPending}
          overlay={true}
        />
        <PageHeader
          title={t("prospects.title")}
          actions={
            <Box sx={{ display: "flex", gap: "16px" }}>
              <Button variant="secondary" onClick={handleNavigate}>
                Import Prospects
              </Button>
              <Button
                data-testid="openProspectDrawer"
                variant="primary"
                onClick={() => {
                  setCurrentProspect({});
                  dispatch(openEditDrawer());
                }}
              >
                {t("prospects.createProspectTitle")}
              </Button>
            </Box>
          }
          filters={<ProspectFilters setFilters={setFilters} />}
        />

        <ViewProspectDrawer />
        <EditProspectDrawer />

        {table.getIsSomeRowsSelected() && (
          <Box>
            <Button variant={"secondary"} size="sm" onClick={openMultiSeqEnroll}>
              Enroll ({selectedContactIdList.length}) in Sequence
            </Button>
          </Box>
        )}
        {/* TODO filters and multi select display */}

        {prospectsLoading ? (
          <LoadingSpinner />
        ) : (
          <TsTable
            table={table}
            handleCellClick={handleCellClick}
            notFoundProps={{
              title: "No prospects found",
              description: (
                <span>
                  Begin adding or importing prospects by clicking the <br /> <b>"Import Prospects"</b> and{" "}
                  <b>"Add Prospect"</b> buttons above.
                </span>
              ),
              Icon: IdentificationIcon,
            }}
          />
        )}

        <SeqEnrollDialog
          isOpen={seqEnrollShowDialog}
          handleClose={handleSeqEnrollClose}
          handleSubmit={handleSeqEnroll}
        />
      </Box>
    </FormProvider>
  );
}
