import PoweredByKn from "@/common/assets/svg/powered_by_kn.svg";
import SalesAiFull from "@/common/assets/svg/salesaifull.svg";
import Button from "@/common/components/button";
import Stepper from "@/common/components/stepper/Stepper";
import routes from "@/common/constants/routes";
import { getTeam, getTeamUsers } from "@/redux/reducers/settings/team/get";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import { useAuth0 } from "@auth0/auth0-react";
import { ArrowRightStartOnRectangleIcon } from "@heroicons/react/16/solid";
import { Box, Divider, Typography, useTheme } from "@mui/material";
import { ArrowRightOutline } from "heroicons-react";
import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import BillingSettings from "./steps/billing";
import CompanySettings from "./steps/company";
import TermsOfServicePage from "./steps/terms-of-service";
import { getBillingInfo } from "@/redux/reducers/settings/billing";
import { createTeam, updateTeam } from "@/redux/reducers/settings/team";
import { KnTeamUserRoleUserRoleEnum } from "@/services/generated";

enum OnboardingTabs {
  TERMS_OF_SERVICE = 0,
  COMPANY_SETTINGS = 1,
  BILLING = 2,
}

export default function Onboarding() {
  const { user, logout } = useAuth0();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const ONBOARDING_STEPS = useMemo(() => {
    return [
      {
        type: OnboardingTabs.TERMS_OF_SERVICE,
        label: "Terms of Service",
        route: routes.onboard.termsOfService.path,
        nextRoute: routes.onboard.team.path,
        hideNavigationButtons: true,
      },
      {
        type: OnboardingTabs.COMPANY_SETTINGS,
        label: "Company Settings",
        route: routes.onboard.team.path,
        nextRoute:
          user?.team_user_role === KnTeamUserRoleUserRoleEnum.Representative
            ? routes.root.path
            : routes.onboard.billing.path,
      },
      ...(user?.team_user_role === KnTeamUserRoleUserRoleEnum.Representative
        ? []
        : [
            {
              type: OnboardingTabs.BILLING,
              label: "Billing",
              route: routes.onboard.billing.path,
              nextRoute: routes.root.path,
            },
          ]),
    ];
  }, [user?.team_user_role]);

  const [activeTab, setActiveTab] = useState(ONBOARDING_STEPS[0]);

  const dispatch = useAppDispatch();

  const team = useAppSelector(({ settings }) => settings.team.team);
  const draftTeam = useAppSelector(({ settings }) => settings.team.draftTeam);

  const nextButtonLabel = useMemo(() => {
    if (activeTab.type === ONBOARDING_STEPS.length - 1) return "Get Started";

    if (activeTab.type === OnboardingTabs.COMPANY_SETTINGS && draftTeam && !team) return "Create Company";

    return "Next";
  }, [ONBOARDING_STEPS.length, activeTab.type, draftTeam, team]);

  useEffect(() => {
    const activeTab = ONBOARDING_STEPS.find((step) => step.route === location.pathname);

    if (activeTab) {
      setActiveTab(activeTab);
    } else {
      navigate(routes.onboard.termsOfService.path);
    }
  }, [ONBOARDING_STEPS, location.pathname, navigate]);

  const goToNextStep = async () => {
    if (activeTab.type === OnboardingTabs.COMPANY_SETTINGS && draftTeam) {
      if (!team) {
        await dispatch(createTeam(draftTeam));
      } else if (user?.team_user_role === KnTeamUserRoleUserRoleEnum.Manager) {
        await dispatch(updateTeam());
      }
    }

    navigate(activeTab.nextRoute);
  };

  useEffect(() => {
    dispatch(getTeam());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTeamUsers());
  }, [dispatch]);

  useEffect(() => {
    if (team?.id) {
      dispatch(getBillingInfo(team.id));
    }
  }, [team, dispatch]);

  const handleLogout = () => {
    return logout({
      logoutParams: {
        returnTo: `${window.location.origin}`,
      },
    });
  };

  return (
    <>
      <Box
        sx={{
          background: theme.palette.bg.default,
          height: "100vh",
          overflowX: "hidden",
          display: "flex",
          flexDirection: "column",
          padding: "70px 80px",
          alignItems: "flex-start",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: "28px",
                fontWeight: "600",
                lineHeight: "36px",
              }}
            >
              Welcome
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
              }}
            >
              Complete the following steps to start using SalesAi
            </Typography>
          </Box>
          <Box>
            <img src={SalesAiFull} alt="SalesAI Logo" width="54.047px" height="54.232px" />
          </Box>
        </Box>
        <Box
          sx={{
            background: "white",
            borderRadius: "8px",
            width: "100%",
            display: "flex",
            padding: "42px 100px 32px 100px",
            flexDirection: "column",
            alignItems: "center",
            gap: "32px",
            alignSelf: "stretch",
          }}
        >
          <Box sx={{ width: "70%" }}>
            <Stepper
              steps={ONBOARDING_STEPS.map((step) => ({ idx: step.type, label: step.label }))}
              activeStep={ONBOARDING_STEPS.indexOf(activeTab)}
            />
          </Box>

          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              gap: "32px",
            }}
          >
            {activeTab.type === OnboardingTabs.TERMS_OF_SERVICE ? (
              <TermsOfServicePage onAccept={goToNextStep} />
            ) : activeTab.type === OnboardingTabs.COMPANY_SETTINGS ? (
              <CompanySettings />
            ) : (
              <BillingSettings />
            )}

            {!activeTab.hideNavigationButtons && (
              <Box>
                <Divider />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "end",
                    gap: "20px",
                    marginTop: "20px",
                    width: "100%",
                    alignItems: "end",
                  }}
                >
                  <Button
                    leftIcon={<ArrowRightOutline size={16} />}
                    variant="primary"
                    type="button"
                    disabled={!team?.id && (draftTeam?.name === "" || draftTeam === null)}
                    onClick={goToNextStep}
                  >
                    {nextButtonLabel}
                  </Button>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            background: "white",
            borderRadius: "8px",
            padding: "16px",
          }}
        >
          <img src={PoweredByKn} alt="Kennected Logo" />
          <Button size="sm" variant="secondary" onClick={handleLogout} rightIcon={<ArrowRightStartOnRectangleIcon />}>
            Log Out
          </Button>
        </Box>
      </Box>
    </>
  );
}
