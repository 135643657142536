import { Box, Divider, Typography } from "@mui/material";
import Avatar from "@/common/components/avatar";
import { ArrowDownLeftIcon, ArrowUpRightIcon } from "@heroicons/react/24/outline";
import { KnAiAgent } from "@/services/generated";

type Prop = {
  agent: KnAiAgent;
  selected?: boolean;
  onClick?: () => void;
};

export default function AiAgent({ agent, selected, onClick }: Prop) {
  return (
    <Box
      key={agent.agent_id}
      onClick={onClick}
      sx={{
        borderRadius: "8px",
        padding: "16px",
        cursor: "pointer",
        border: "1px solid #E7EBED",
        boxShadow: selected ? "0px 0px 0px 2px #2C72E2" : "none",
      }}
    >
      <Box sx={{ display: "flex", gap: "8px", alignItems: "center", marginBottom: "16px" }}>
        <Avatar label={agent?.voice_name} src={agent?.voice_image_url} />
        <Box>
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: "24px",
              fontSize: "16px",
              letterSpacing: "0.2px",
            }}
          >
            {agent?.agent_label || agent?.voice_name}
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: 400,
              lineHeight: "20px",
              letterSpacing: "0.2px",
            }}
          >
            {agent?.phone_number}
          </Typography>
        </Box>
      </Box>

      <Divider />
      <Box sx={{ display: "flex", gap: "8px", marginTop: "16px" }}>
        {agent?.call_direction === "OUTBOUND" ? (
          <ArrowUpRightIcon
            width="24"
            height="24"
            style={{
              color: "#1A2135",
              background: "#F4F5F7",
              padding: "4px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        ) : (
          <ArrowDownLeftIcon
            width="24"
            height="24"
            style={{
              color: "#1A2135",
              background: "#F4F5F7",
              padding: "4px",
              borderRadius: "50%",
              cursor: "pointer",
            }}
          />
        )}

        <Box
          sx={{
            fontWeight: 600,
            lineHeight: "24px",
            fontSize: "16px",
            letterSpacing: "0.2px",
          }}
        >
          {agent?.call_flow}
        </Box>
      </Box>
    </Box>
  );
}
