import { Box, InputBaseComponentProps, TextField, Typography } from "@mui/material";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { forwardRef } from "react";
import React from "react";

export interface InputProps {
  label?: string;
  disabled?: boolean;
  isOptional?: boolean;
  multiline?: boolean;
  placeholder?: string;
  type?: string;
  value?: string | number;
  error?: string | undefined | null;
  onChange?: (e: any) => void;
  sx?: SxProps<Theme>;
  rows?: number;
  inputProps?: InputBaseComponentProps;
  hasRoundedBottomBorders?: boolean;
  hasRoundedTopBorders?: boolean;
  leftPadding?: number;
  InputProps?: InputBaseComponentProps;
  rightIcon?: JSX.Element;
  extendOnFocus?: boolean;
  hasBorder?: boolean;
  hasSideBorders?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      rightIcon,
      value,
      multiline = false,
      placeholder = "",
      error,
      disabled,
      type = "text",
      sx,
      rows = 1,
      inputProps = {},
      hasRoundedBottomBorders = true,
      hasRoundedTopBorders = true,
      leftPadding = 16,
      extendOnFocus = false,
      hasBorder = true,
      hasSideBorders = true,
    }: InputProps,
    ref,
  ) => {
    const theme = useTheme();
    const [isFocused, setIsFocused] = React.useState(false);

    const baseStyles: SxProps<Theme> = {
      "& .MuiOutlinedInput-root": {
        paddingLeft: `${leftPadding}px`,
        borderBottomRightRadius: hasRoundedBottomBorders ? "8px" : "0px",
        borderBottomLeftRadius: hasRoundedBottomBorders ? "8px" : "0px",
        borderTopRightRadius: hasRoundedTopBorders ? "8px" : "0px",
        borderTopLeftRadius: hasRoundedTopBorders ? "8px" : "0px",

        "& .MuiInputBase-inputMultiline": {
          minHeight: "unset",
          transition: "height 0.2s",
        },
      },

      "& fieldset": {
        border: "none",
      },
    };

    const errorStyles: SxProps<Theme> = error
      ? {
          "& .MuiInputBase-root": {
            "& > fieldset": {
              borderColor: theme.palette.commonColors.danger,
            },
          },
        }
      : {};

    return (
      <Box sx={{ position: "relative", width: "100%" }}>
        <TextField
          ref={ref}
          multiline={multiline}
          placeholder={placeholder}
          type={type}
          disabled={disabled}
          onChange={onChange}
          rows={multiline && extendOnFocus && isFocused ? rows : 1}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          value={value}
          inputProps={inputProps}
          sx={{
            ...baseStyles,
            ...errorStyles,

            "& .MuiInputBase-root": {
              border: !hasBorder
                ? "none"
                : `1px solid ${error ? theme.palette.border.danger : theme.palette.border.default}`,
              borderLeft: hasSideBorders
                ? `1px solid ${error ? theme.palette.border.danger : theme.palette.border.default}`
                : "none",
              borderRight: hasSideBorders
                ? `1px solid ${error ? theme.palette.border.danger : theme.palette.border.default}`
                : "none",
            },
          }}
        />

        {rightIcon ? (
          <Box
            sx={{
              position: "absolute",
              right: "16px",
              top: "50%",
              transform: "translateY(-40%)",
            }}
          >
            {rightIcon}
          </Box>
        ) : null}

        {error && (
          <Typography variant="body2" style={{ color: theme.palette.commonColors.danger, fontSize: "12px" }}>
            {error || "This field is required"}
          </Typography>
        )}
      </Box>
    );
  },
);

export default Input;
