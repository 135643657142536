import { Typography, Avatar as MuiAvatar, Stack, useTheme } from "@mui/material";

export type AvatarProps = {
  label?: string;
  size?: "xsmall" | "small" | "medium" | "large" | "xlarge";
  src?: string;
  withLabel?: boolean;
  background?: string;
  isIconSize?: any;
};

function stringAvatar(name?: string) {
  if (!name || typeof name !== "string") return { children: "" };
  const words = name.trim().split(" ");
  if (words.length === 1) {
    return {
      children: words[0][0] || "",
    };
  }
  return {
    children: `${words[0][0] || ""}${words[1][0] || ""}`,
  };
}

export default function Avatar(props: AvatarProps) {
  const theme = useTheme();
  const { size = "medium", background = theme.palette.mint.p, isIconSize } = props;
  return (
    <Stack direction="row" alignItems="center" gap={1}>
      <MuiAvatar
        alt={props.label}
        src={props.src}
        style={{
          fontWeight: 500,
        }}
        sx={{
          textTransform: "uppercase",
          background: background,
          color: theme.palette.icon.default,
          fontSize: {
            xsmall: "10px",
            small: "12px",
            medium: "14px",
            large: "18px",
            xlarge: "22px",
          }[size],

          width: {
            xsmall: 20,
            small: 24,
            medium: 36,
            large: 42,
            xlarge: 48,
          }[size],
          height: {
            xsmall: 20,
            small: 24,
            medium: 36,
            large: 42,
            xlarge: 48,
          }[size],
          ...(isIconSize && {
            "& .MuiAvatar-img": {
              height: "auto",
              width: "auto",
            },
          }),
        }}
        {...stringAvatar(props.label || "")}
      />
      {props.withLabel && props.label && (
        <Typography
          sx={{
            letter: "0.2px",
            lineHeight: "24px",
            fontWeight: 500,

            fontSize: {
              xsmall: "10px",
              small: "12px",
              medium: "14px",
              large: "16px",
              xlarge: "20px",
            }[size],
          }}
        >
          {props.label}
        </Typography>
      )}
    </Stack>
  );
}
