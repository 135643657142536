import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import * as React from "react";
import { createRoot } from "react-dom/client";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from "./App";
import { Helmet } from "react-helmet";

const Loading: React.FC = () => {
  return <div>Loading...</div>;
};

const Root: React.FC = () => {
  return (
    <React.StrictMode>
      <Helmet>
        <title>SalesAi</title>
        <meta name="description" content="This is a description of my page" />
        <meta name="keywords" content="react, meta tags, seo" />
        <meta name="author" content="Kennected" />
        <meta name="viewport" content="width=500, initial-scale=1" />
        <meta property="og:title" content="SalesAi" />
        <meta property="og:description" content="This is a description of my page" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="SalesAi" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" />
        <link
          href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap"
          rel="stylesheet"
        />
        <script type="text/javascript" id="hs-script-loader" async defer src="//js.hs-scripts.com/9204155.js"></script>
      </Helmet>
      <React.Suspense fallback={<Loading />}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </React.Suspense>
    </React.StrictMode>
  );
};

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<Root />);
