import { Box, Button, Dialog, DialogContent, DialogTitle, Slide, Typography } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import React, { useEffect, useMemo, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { getCoreRowModel, getPaginationRowModel, useReactTable } from "@tanstack/react-table";
import { SequenceTemplate } from "@/types/sequence";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import TsTable from "@/common/components/ts-table";
import { IdentificationIcon } from "@heroicons/react/24/outline";
import { getSequencesTemplates } from "@/redux/reducers/sequences/slices/listSequences";
import { SequenceColumns } from "@/common/components/enroll-in-sequence/columns";
import SearchBar from "../searchbar/Searchbar";

interface SeqEnrollDialogProps {
  isOpen: boolean;
  showOverview?: boolean;
  handleClose: () => void;
  handleSubmit: (sequenceId: string) => void;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SeqEnrollDialog({ isOpen, handleClose, handleSubmit }: SeqEnrollDialogProps) {
  const dispatch = useAppDispatch();
  const sequences = useAppSelector((selector) => selector.sequences.listSequences.sequences);
  const [search, setSearch] = useState("");

  useEffect(() => {
    dispatch(getSequencesTemplates());
  }, [dispatch]);

  const getVersionActive = (original: SequenceTemplate): SequenceTemplate | null => {
    const active = original.versions?.find((v) => v.status === "ACTIVE");
    if (!active) return null;
    return {
      ...active,
      // original contains the sum of all version.prospectEnrolled.
      prospectEnrolled: original.prospectEnrolled,
    };
  };

  const sequencesActive = useMemo(() => {
    return (sequences?.map(getVersionActive).filter((s) => !!s) as SequenceTemplate[]) || [];
  }, [sequences]);

  const data = useMemo(() => {
    return sequencesActive?.filter((sequence) => sequence.name?.toLowerCase().includes(search.toLowerCase()));
  }, [sequencesActive, search]);

  const table = useReactTable<SequenceTemplate>({
    data: data || [],
    columns: SequenceColumns,
    pageCount: data?.length,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    initialState: {
      pagination: {
        pageIndex: 0,
        pageSize: 10,
      },
    },
  });

  const selected = table.getSelectedRowModel();
  const selectedSequenceIdList = useMemo(() => {
    return selected.rows.map((r) => r.original.id);
  }, [selected.rows]);

  const onSubmit = () => {
    console.log(selectedSequenceIdList);
    handleSubmit(selectedSequenceIdList[0]);
  };

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            color: "#1A2135",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "28px",
            letterSpacing: "0.2px",
          }}
        >
          Enroll in Sequence
        </Typography>
        <Box
          onClick={handleClose}
          sx={{
            display: "flex",
            alignSelf: "center",
          }}
        >
          <CloseIcon
            sx={{
              cursor: "pointer",
              color: "#1A2135",
            }}
          />
        </Box>
      </DialogTitle>

      <Box
        sx={{
          background: "#E0E0E0",
          height: "1px",
          flexShrink: 0,
          alignSelf: "stretch",
        }}
      />
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
          minWidth: "600px",
        }}
      >
        <Typography fontWeight={600} fontSize={16}>
          Select Sequence
        </Typography>
        <SearchBar
          sx={{ flexGrow: 1 }}
          placeholder="Search by Name"
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
          }}
        />
        <TsTable
          table={table}
          notFoundProps={{
            title: "No prospects found",
            description: (
              <span>
                Begin adding or importing prospects by clicking the <br /> <b>"Import Prospects"</b> and{" "}
                <b>"Add Prospect"</b> buttons above.
              </span>
            ),
            Icon: IdentificationIcon,
          }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            gap: "20px",
          }}
        >
          <Button size="small" variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button size="small" onClick={onSubmit}>
            Enroll
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
