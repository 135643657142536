import Button from "@/common/components/button";
import Dropdown from "@/common/components/dropdown";
import FormLabel from "@/common/components/form-label/FormLabel";
import Input from "@/common/components/input";
import DrawerLayout from "@/layouts/DrawerLayout";
import { DripTemplateRequestIntervalTypeEnum, DripTemplateResponse } from "@/services/generated";
import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Typography } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import FormControl from "@mui/material/FormControl";
import { useEffect, useMemo } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import * as Yup from "yup";

interface DraftTemplateDripProps {
  dripData?: DripTemplateResponse;
  closeDrawer: () => void;
  isOpen: boolean;
  onAddAction: (data: any) => void;
}

const schema = Yup.object({
  actionName: Yup.string().required("This field is required"),
  batchSize: Yup.number().typeError("Invalid value").integer("Must be an integer").required("This field is required"),
  interval: Yup.number().typeError("Invalid value").integer("Must be an integer").required("This field is required"),
  intervalType: Yup.string().required("This field is required"),
});

export type DripForm = Yup.InferType<typeof schema>;

export default function DraftTemplateDrip({ closeDrawer, isOpen, onAddAction, dripData }: DraftTemplateDripProps) {
  const methods = useForm<DripForm>({
    resolver: yupResolver(schema),
  });

  const { control, reset, setError, getValues, setValue } = methods;

  const onSubmit = () => {
    onAddAction(getValues());
  };

  const intervalUnits = useMemo(() => {
    return Object.values(DripTemplateRequestIntervalTypeEnum).map((value) => ({
      label: value,
      value,
    }));
  }, []);

  useEffect(() => {
    if (dripData) {
      setValue("actionName", dripData.actionName!);
      setValue("batchSize", dripData.batchSize!);
      setValue("interval", dripData.interval!);
      setValue("intervalType", dripData.intervalType!);
    }
  }, [dripData, setValue]);

  const DripForm = () => (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        height: "100%",
        justifyContent: "space-between",
        marginLeft: "5px",
        marginRight: "5px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: 600,
          }}
        >
          Details
        </Typography>
        <FormControl>
          <Controller
            name="actionName"
            control={control}
            render={({ field, fieldState }) => (
              <>
                <FormLabel aria-required label="Action Name" infoIcon />
                <Input {...field} placeholder="Action Name" error={fieldState?.error?.message} />
              </>
            )}
          />
        </FormControl>
        <Box sx={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", height: "48px", gap: "16px" }}>
          <FormControl>
            <Controller
              name="batchSize"
              control={control}
              render={({ field, fieldState }) => (
                <Box>
                  <FormLabel label="Batch Size" infoIcon />
                  <Input {...field} placeholder="Batch Size" error={fieldState.error?.message} />
                </Box>
              )}
            />
          </FormControl>
          <Box sx={{ gridColumn: "span 2" }}>
            <FormControl>
              <Controller
                name="interval"
                control={control}
                render={({ field, fieldState }) => (
                  <>
                    <FormLabel label="Interval" infoIcon />
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "16px",
                      }}
                    >
                      <Box>
                        <Input {...field} placeholder="Interval" error={fieldState.error?.message} />
                      </Box>
                      <FormControl>
                        <Controller
                          name="intervalType"
                          control={control}
                          render={({ field, fieldState }) => (
                            <Dropdown
                              {...field}
                              options={intervalUnits}
                              error={fieldState.error?.message}
                              isClearable={false}
                              onChange={(value) => {
                                field.onChange(value.value);
                              }}
                              menuMaxHeight="200px"
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </>
                )}
              />
            </FormControl>
          </Box>
        </Box>
      </Box>

      <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", height: "48px", gap: "16px" }}>
        <Button fullWidth variant="secondary" type="button" onClick={closeDrawer}>
          Cancel
        </Button>
        <Button onClick={methods.handleSubmit(onSubmit)} fullWidth type="button" variant="primary">
          Save Action
        </Button>
      </Box>
    </div>
  );

  return (
    <Drawer open={isOpen} onClose={closeDrawer} anchor="right">
      <DrawerLayout title="Drip" closeDrawer={closeDrawer}>
        <FormProvider {...methods}>
          <form
            style={{
              height: "100%",
            }}
          >
            <DripForm />
          </form>
        </FormProvider>
      </DrawerLayout>
    </Drawer>
  );
}
