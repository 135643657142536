import Box from "@mui/material/Box";
import Searchbar from "@/common/components/searchbar/Searchbar";

import { DatePicker } from "@/common/components/date-picker/DatePicker";

export type InboxFiltersInput = {
  text: string;
  updatedAtS: Date | null;
  updatedAtE: Date | null;
};

type InboxFiltersProps = {
  filters: InboxFiltersInput;
  setFilters: (filters: InboxFiltersInput) => void;
};

export default function InboxFilters({ filters, setFilters }: InboxFiltersProps) {
  return (
    <form>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 2,
        }}
      >
        <Box>
          <Searchbar
            placeholder="Search"
            value={filters.text}
            onChange={(event) => setFilters({ ...filters, text: event.target.value })}
          />
        </Box>
        <Box>
          <DatePicker
            range={true}
            value={[filters.updatedAtS, filters.updatedAtE]}
            onChange={(range: any) => {
              if (range.dateStart && range.dateEnd) {
                setFilters({
                  ...filters,
                  updatedAtS: new Date(range.dateStart),
                  updatedAtE: new Date(range.dateEnd),
                });
              } else {
                setFilters({
                  ...filters,
                  updatedAtS: null,
                  updatedAtE: null,
                });
              }
            }}
          />
        </Box>
      </Box>
    </form>
  );
}
