import { Controller, useForm } from "react-hook-form";
import Input from "@/common/components/input";
import { Box, FormControl, useTheme } from "@mui/material";
import { useAppDispatch, useAppSelector } from "@/redux/store";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FileUpload from "@/common/components/file-upload";
import { useEffect } from "react";
import FormLabel from "@/common/components/form-label/FormLabel";
import { setDraftTeam } from "@/redux/reducers/settings/team";
import { setCanSaveAiVoice, setDraftAiVoice } from "@/redux/reducers/settings/team-ai-voice";
import ToolTip from "@/common/components/tool-tip";
import { ReactComponent as InfoIcon } from "@/common/assets/svg/settings/information-circle.svg";
import { Loading } from "@kennected/components";
import PhoneInput from "@/common/components/phone-input";
import { phoneValidationErrorMessage, phoneValidationRegex } from "@/common/constants/phone-validation";

const schema = Yup.object({
  id: Yup.string(),
  name: Yup.string().required(),
  location: Yup.string(),
  domain: Yup.string().required(),
  logoImgUrl: Yup.string(),
  media: Yup.mixed().nullable(),
  twilioAccountSid: Yup.string(),
  transferPhoneNumberDefault: Yup.string().matches(phoneValidationRegex, phoneValidationErrorMessage),
  transferPhoneNumberSales: Yup.string().matches(phoneValidationRegex, phoneValidationErrorMessage),
  transferPhoneNumberBilling: Yup.string().matches(phoneValidationRegex, phoneValidationErrorMessage),
  transferPhoneNumberSupport: Yup.string().matches(phoneValidationRegex, phoneValidationErrorMessage),
}).required();

const getTooltipText = (field: string) => {
  switch (field) {
    case "transferPhoneNumberDefault":
      return "Your default transfer number for the company";
    case "transferPhoneNumberSales":
      return "Your sales team's transfer number for SalesAi Voice";
    case "transferPhoneNumberBilling":
      return "Your billing team's transfer number for SalesAi Voice";
    case "transferPhoneNumberSupport":
      return "Your support team's transfer number for SalesAi Voice";
    default:
      return "";
  }
};

export default function BasicInformation() {
  const theme = useTheme();
  const team = useAppSelector(({ settings }) => settings.team.team);
  const teamPhoneSMS = useAppSelector(({ settings }) => settings.team.teamPhoneSMS);
  const teamAiVoice = useAppSelector(({ settings }) => settings.teamAiVoice.active);
  const teamAiVoiceLoading = useAppSelector(({ settings }) => settings.teamAiVoice.loading);
  const teamAiVoiceUpdating = useAppSelector(({ settings }) => settings.teamAiVoice.updating);
  const dispatch = useAppDispatch();
  const { control, reset, formState, watch, setValue } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      id: team?.id || "",
      name: team?.name || "",
      location: team?.location || "",
      domain: team?.domain || "",
      logoImgUrl: team?.logoImgUrl || "",
      media: null,
      twilioAccountSid: teamAiVoice?.twilioAccountSid || "",
      transferPhoneNumberDefault: teamAiVoice?.transferPhoneNumberDefault || "",
      transferPhoneNumberSales: teamAiVoice?.transferPhoneNumberSales || "",
      transferPhoneNumberBilling: teamAiVoice?.transferPhoneNumberBilling || "",
      transferPhoneNumberSupport: teamAiVoice?.transferPhoneNumberSupport || "",
    },
  });

  useEffect(() => {
    reset({
      id: team?.id || "",
      name: team?.name || "",
      location: team?.location || "",
      domain: team?.domain || "",
      logoImgUrl: team?.logoImgUrl || "",
      media: null,
      twilioAccountSid: teamAiVoice?.twilioAccountSid || "",
      transferPhoneNumberDefault: teamAiVoice?.transferPhoneNumberDefault || "",
      transferPhoneNumberSales: teamAiVoice?.transferPhoneNumberSales || "",
      transferPhoneNumberBilling: teamAiVoice?.transferPhoneNumberBilling || "",
      transferPhoneNumberSupport: teamAiVoice?.transferPhoneNumberSupport || "",
    });
  }, [reset, team, teamAiVoice]);

  const data = watch();

  useEffect(() => {
    if (!formState.isDirty) return;
    if (formState.isValid) {
      dispatch(setDraftTeam(data));
      dispatch(setDraftAiVoice(data));
      dispatch(setCanSaveAiVoice(true));
    } else {
      dispatch(setCanSaveAiVoice(false));
    }
  }, [data, dispatch, formState.isDirty, formState.isValid]);

  const handleFileSelect = (file: File) => {
    setValue("media", file, { shouldDirty: true });
  };

  const transferInput = (
    fieldName:
      | "transferPhoneNumberDefault"
      | "transferPhoneNumberSales"
      | "transferPhoneNumberSupport"
      | "transferPhoneNumberBilling",
    label: string,
  ) => {
    return (
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormLabel label={label} />
              <ToolTip title={getTooltipText(fieldName)} placement={"right"}>
                <InfoIcon
                  height="18.803px"
                  width="20px"
                  style={{ marginBottom: "8px" }}
                  color={theme.palette.icon.informative}
                />
              </ToolTip>
            </Box>
            <PhoneInput {...field} error={fieldState?.error?.message} />
          </FormControl>
        )}
      />
    );
  };

  if (teamAiVoiceLoading || teamAiVoiceUpdating) {
    return <Loading />;
  }

  return (
    <Box
      sx={{
        display: "grid",
        gap: "32px",
        gridTemplateColumns: "repeat(2, 1fr)",
      }}
    >
      <Controller
        name="name"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Company Name" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="location"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Company Location" />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <Controller
        name="domain"
        control={control}
        render={({ field, fieldState }) => (
          <FormControl>
            <FormLabel label="Domain" aria-required />
            <Input {...field} error={fieldState.error?.message} />
          </FormControl>
        )}
      />
      <FormControl>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <FormLabel label="Company Phone SMS" />
          <ToolTip
            title={`Complete Brand and Campaign Registration to receive an A2P compliant phone number for sending SMS.`}
            placement={"right"}
          >
            <InfoIcon
              height="18.803px"
              width="20px"
              style={{ marginBottom: "8px" }}
              color={theme.palette.icon.informative}
            />
          </ToolTip>
        </Box>
        <Input
          value={teamPhoneSMS}
          placeholder="Complete Brand and Campaign Registration to receive a number"
          disabled
        />
      </FormControl>
      {transferInput("transferPhoneNumberDefault", "Phone Transfer")}
      {transferInput("transferPhoneNumberSales", "Phone Sales")}
      {transferInput("transferPhoneNumberBilling", "Phone Billing")}
      {transferInput("transferPhoneNumberSupport", "Phone Support")}
      <FileUpload label="Company Logo" onFileSelect={handleFileSelect} iconPlaceholderUrl={team?.logoImgUrl} />
    </Box>
  );
}
